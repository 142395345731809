<template>
  <div>
    <iframe
      id="video-quest"
      :src="getQuestLink"
      width="120"
      height="210"
      style="display: none"
    ></iframe>
  </div>
</template>

<script>
export default {
  computed: {
    getQuestLink() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "vq/";
      const questLink = `${baseUrl}${this.$route.params.id}?is_demo=true`;
      return questLink;
    },
  },
  mounted() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  metaInfo() {
    return {
      title: "moovee - " + this.$route.params.name,
    };
  },
};
</script>
